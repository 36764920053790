import React from "react";
import config from "../../config/config";
import { LogDetalleCheckout } from './LogDetalleCheckout';

declare global {
    interface Window {
        ePayco:any;
        ePayco2:any;
    }

    interface Document {
        onHistoryGo:any;
    }
}

export const checkoutRender = () =>{
    const script:any = document.createElement('script');
    script.src = config.EPAYCO_APP_CHECKOUT;
    document.body.appendChild(script);
};




const getDataFetType = (key:string, value:string) => {
    switch (key) {
        case '1':
            return `1 : ${value}`;
        case '2':
            return `2 : ${value}`;
        case '4':
            return `4 : ${value}`;
    }
}

const validaFeetype = (name:string, digito:number, monto:string) => {

    if (digito === 0 || digito === 1) {
        return (parseInt(monto) !== 0);
    } else {

        if (digito === 2 && name === "sumaServiceAmount") {
            return true;
        } else if (digito === 3 && name === "sumaDeviceInstAmtQIInfo") {
            return true;
        } else if (digito === 5 && name === " sumaAditionalContents") {
            return true;
        } else {
            return false
        }
    }
}

const BuildDataFeetype = (
    feetype_name: string,
    id_feetype: string,
    checked: boolean,
    deuda: string,
    value: string,
    name: string
) => {
    return ({
        feetype_name,
        id_feetype,
        checked,
        deuda,
        value,
        name
    })
}

const key: any = {
    'TELEBUCARAMANGA': config.REACT_APP_TELEBUCARAMANGA_RECAUDO_PUBLIC_KEY,
    'METROTEL'       : config.REACT_APP_METROTEL_RECAUDO_PUBLIC_KEY,
    'MOVISTAR'       : config.REACT_APP_MOVISTAR_RECAUDO_PUBLIC_KEY,
};



interface DataCheckout {
    //Parametros compra (obligatorio)
    name       : string;
    description: string;
    invoice    : string;
    currency   : string;
    amount     : string;
    tax_base   : string;
    tax        : string;
    country    : string;
    lang       : string;
    external   : string;

    //Atributos opcionales
    confirmation   : string;
    response       : string;
    nombre_proyecto: string;
}

const PrepareDataCheckout = (data:any, debit: boolean)=>{
    return ({
        name       : `Pago ${data.extra1} Movistar`,
        description: debit ? `Domiciliación Suscribir futuros cobros cta` : `Pago ${data.extra1} Movistar`,
        invoice    :  data.invoice ? data.invoice : data.extra11  + '-' + Math.round(new Date().getTime() / 1000),
        currency   : 'COP',
        amount     :  data.amount,
        tax_base   : '0',
        tax        : '0',
        country    : 'co',
        lang       : 'es',
        external   : 'false',

        //Atributos opcionales
        extra1: data.newExtra1 || "",
        extra3       :  data.extra3,
        extra2       :  data.extra2 ? data.extra2 : '',
        extra4: sessionStorage.getItem("shoppingCartTipo"),
        extra6       :  data.extra6,
        extra9       :  data.extra9,
        extra10      :  data.extra10,
        extra11      :  data.extra11,
        confirmation : `${config.EPAYCO_REST_RECAUDO_API_CONFIRM}`,
        response     : data.response,
        nombre_proyecto: ''
    })
}

const PrepareDataCheckoutFilial = (data:any)=>{
    let dataPayment: any = JSON.parse(sessionStorage.getItem('dataPayment') || '');
    data.extra5 = dataPayment.canalPago;
    return ({
        name       : `Pago servicio ${data.extra1} ${data.empresaPago}  `,
        description: `Pago servicio ${data.empresaPago} ` ,
        invoice    : data.extra11  + '-' + Math.round(new Date().getTime() / 1000),
        currency   : 'COP',
        amount     : data.amount,
        tax_base   : '0',
        tax        : '0',
        country    : 'co',
        lang       : 'es',
        external   : 'false',

        //Atributos opcionales
        extra5       : data.extra5,
        extra2       :  data.extra2 ? data.extra2 : '',
        extra9       :  data.extra9,
        extra10      :  data.extra10,
        extra11      :  data.extra11,
        confirmation   : `${config.EPAYCO_REST_RECAUDO_API_CONFIRM}`,
        response       : data.response,
        nombre_proyecto: data.empresaPago
    })
}



export const OpenCheckout = async (
        form:any,
        callB:()=> void,
        multiples:boolean,
        debit=false
    ) => {
    let data:DataCheckout;
    let proyecto   :string   = 'MOVISTAR';
    let urlResumen :string   = `${config.urlFront}/${multiples ? 'resume_multiples_facturas':'resume'}`;
    if (form.novum) {
        form.movil_url = "https://msecure.epayco.io";
        urlResumen = urlResumen + `?novum=true&urlcallback=${form.urlcallback}`
    }
    form.response = urlResumen;
    
    if(form.empresaPago && (form.empresaPago === 'telebucaramanga' || form.empresaPago === 'metrotel')){
        data     = PrepareDataCheckoutFilial(form);
        proyecto = form.empresaPago.toUpperCase();
    }else{
        data = PrepareDataCheckout(form, debit);
    }
    sessionStorage.removeItem('shoppingCartId');
    let ePayco = window.ePayco; // ok
    let handler = ePayco.checkout.configure({
        key:  key[proyecto],
        test: config.checkOutPruebas, //Modo produccion,
    });
    await LogDetalleCheckout({
        dataCheckout: data,
        form
    });
    handler.open(data);
    handler.onClosed(() => callB());

    document.onHistoryGo = function () {
        return false;
    }
}